import React from 'react';
import "../../IndividualLite.scss"
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';
import { InputField } from '../../../../../components';
import AntDateSelect from '../../../../../components/DateSelects/AntDateSelect';
import { Col, Row } from 'reactstrap';
import Agreements from './Agreements';

const TEXT = [
    {
        id: 0,
        label: "IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING AN ACCOUNT:",
        subText: [
            {
                ind: 10,
                subtext: "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your company formation documents, business licenses, business address, tax identification number, and other information that will allow us to identify your business. We may ask to see your other identifying or ownership documents."
            },
        ],
    },
    {
        id: 1,
        label: "Acknowledgement of Receipt",
        subText: [
            {
                ind: 11,
                subtext: "I hereby acknowledge receipt of this Business Customer Identification Program Notice and further acknowledge that I understand its contents"
            },
        ],
    },
    {
        id: 2,
        label: "Regulation GG – Unlawful Internet Gambling Disclosure",
        subText: [
            {
                ind: 12,
                subtext: "I hereby acknowledge receipt of FV Bank International Inc.’s Regulation GG Unlawful Internet Gambling Disclosure. By signing below, I certify that I am not now engaged in, and during the life of the Account, shall not engage in any activity or business that is unlawful under the Unlawful Internet Gambling Enforcement Act of 2006, 31 USG 5361, et seq., (the “UIGEA”). I understand that I may not use this Account or any other service to receive any funds, transfer, credit, instrument, or proceeds that arise from a business that is unlawful under the UIGEA. I agree that if I am asked to process a transaction that I believe is restricted under the UIGEA, I will block the transaction and take any other action deemed to be reasonable under the UIGEA."
            },
        ],
    },
    {
        id: 3,
        label: "Marijuana-Related Business Disclosure",
        subText: [
            {
                ind: 13,
                subtext: "I hereby acknowledge receipt of FV Bank International Inc’s Marijuana-Related Business Disclosure."
            },
            {
                ind: 23,
                subtext: "By signing below, I certify that I am not now engaged in, and during the life of the Account, will not conduct any marijuana-related business activity, as outlined in the Marijuana Related Business Disclosure. Failing to alert FV Bank International Inc. that a Beneficial Owner(s) own or directly control a marijuana related business or are utilizing FV Bank International, Inc. to receive, accept, hold or maintain funds derived from a directly marijuana-related business are grounds for immediate termination of all banking services."
            },
        ],
    },
    {
        id: 4,
        label: "Third Party Data Processing Disclosure",
        subText: [
            {
                ind: 14,
                subtext: "By entering your data into this application form, you acknowledge and agree that you are providing your consent for the sharing of your data with third-party service providers of FV Bank in order to process your application. The information you provide will be used to evaluate your application and may be shared with third-party service providers for the purposes of processing your application. These third-party service providers may include credit bureaus, identity verification services, and other entities that provide necessary services and infrastructure in connection with your application."
            },
            {
                ind: 24,
                subtext: "You understand and agree that the third-party service providers may have access to your personal information, including but not limited to your name, address, social security number, date of birth, employment information, and financial information. These third-party service providers will be required to use your personal information solely for the purposes of processing your application, and they will be required to maintain the confidentiality and security of your personal information."
            },
            {
                ind: 34,
                subtext: "By submitting your application, you consent to the use and disclosure of your personal information in accordance with this legal disclosure. You also acknowledge and agree that we may use your personal information for other purposes as permitted by law."
            },
        ],
    },
]

const Page7 = ({ asLabel, data, hasError, handleChange, handleValidation,validationkeyValue }) => {
    return (
        <div className='mt-3'>
            {
                TEXT.map(el => {
                    return (
                        <div key={el.id}>
                            <h2 className='pt-2' key={el.id}>{el.label}</h2>
                            {
                                el.subText.map(e => (<p key={e.ind}>{e.subtext}</p>))
                            }
                        </div>
                    )
                })
            }

            {/* ================ */}
            <p className='pt-3'>
                If you do not wish to provide your consent for the sharing of your data with third-party service providers, you should not proceed with your application.
            </p>
            <p>
                Please review our privacy policy for more information about how we collect, use, and disclose your personal information.
            </p>

            <p className='bold-text pt-3'>
                I understand that the Business Plus account is a restricted use account and that it is intended for operational expenses of my direct business only and for receiving deposit from direct customers for docmented commercial purposes only. I also understand that the Business Plus account is not inetended for money servcies business, non bank finanical institutions or similair. If I require these servcies, once my Business Plus Account is apporved, I will request an enhanced due diligence approval for a Business Prime account.
            </p>

            <Row>
                <QuestionRadioGroup
                    field={"tnc"}
                    style={{ fontSize: 15 }}
                    // question="I have read and understand the above disclosures and terms."
                    question={<span> I have read and understand the above  <a href="/business-plus/disclosure" target="_blank" rel="noopener noreferrer">disclosures and terms</a></span>}
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    isDisabled={asLabel}
                    defaultValue={false}

                />
            </Row>

            <Row className='mb-3'>
                {/* <Col className='mt-3 col-lg-6 col-12 d-flex justify-content-lg-start justify-content-center'>
                    <Agreements
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                    />
                </Col> */}
                <Col className='mt-3 c col-12'>
                    <InputLabel label="Name" labelStyle={{ fontSize: 15 }} required={validationkeyValue["firstName"]?.required} />
                    <Row>
                        <InputField
                            className="col-md-6"
                            placeholder={"Enter First Name"}
                            value={data?.firstName}
                            onChange={(v) => handleChange("firstName", v)}
                            error={handleValidation("firstName", data?.firstName)}
                            showErr={hasError}
                            type="text"
                            isDisabled={asLabel}
                        />
                        <InputField
                            className="col-md-6"
                            placeholder={"Enter Last Name"}
                            value={data?.lastName}
                            onChange={(v) => handleChange("lastName", v)}
                            error={handleValidation("lastName", data?.lastName)}
                            showErr={hasError}
                            type="text"
                            isDisabled={asLabel}
                        />
                    </Row>
                </Col>
            </Row>
            <AntDateSelect
                placeholder={"dd/mm/yyyy"}
                format="dd/mm/yyyy"
                value={data?.dateOfApplication}
                onChange={(v) => handleChange("dateOfApplication", v)}
                error={handleValidation('dateOfApplication', data?.dateOfApplication)}
                showErr={hasError}
                className="mb-4"
                label="Date of Application"
                isDisabled={asLabel}
                required={validationkeyValue["dateOfApplication"]?.required}
            />
        </div>
    )
}

export default Page7;